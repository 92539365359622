<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-standar">
      <div class="container-standar mb-10 pad-20">
        <h2 class="text-primary font-weight-7 mb-30 font-30 text-center">
          DOKUMENTASI MANUAL
        </h2>
        <div class="shadow card pad-20 text-center">
          <h2 class="font-26 font-weight-5">
            Tabel list dokumen manual - Daftar Dokumen SIOPAPUA
          </h2>
          <br />
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Keterangan</th>
                <th scope="col">Tanggal Update</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr>
                  <th scope="row">1</th>
                  <td>
                    <span class="text-primary font-weight-6 cursor-pointer"
                      >Aplikasi offline-siopapua v.1.0.8</span
                    >
                  </td>
                  <td>
                    <span class="font-weight-4">26 Agustus 2021</span>
                  </td>
                  <td>
                    <a
                      href="https://drive.google.com/drive/folders/1fNMQhmHHgeapSZTNoikcXdJ6hoZSa7tq?usp=sharing"
                      ><b-button variant="primary"
                        ><b-icon icon="download" variant="white"></b-icon>
                        Download</b-button
                      ></a
                    >
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <br />
          <p>
            Untuk menggunakan Applikasi Offline Siopapua, Download file diatas.
            Setelah berhasil di download, buka folder offline-siopapua v.1.0.8,
            kemudian 'double click' pada file offline-siopapua Setup 1.0.8.exe
            untuk install aplikasi. <br />
            Cara penggunaan Aplikasi Offline Siopapua ada di Buku panduan
            berikut:
            <a
              href="https://drive.google.com/file/d/1B2Vxsmm0YwyOpQ7nQvcQ3oPhg91xfWXg/view?usp=sharing"
              >Buku Panduan</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("SET_BG", false);
  },
};
</script>
